import { Row } from 'antd'
import Title from '../Typography/Title'

export const FallBackComponent = () => {
  return (
    <Row align="middle" justify="center" style={{ height: '100%' }}>
      <Title level={3}>Something went wrong</Title>
    </Row>
  )
}
