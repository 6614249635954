import { useContext } from 'react'
import {
  ConnectModalContext,
  ModalConnectContextType,
} from '../contexts/walletModalContext'

export const useConnectWalletModal = (): ModalConnectContextType => {
  const context = useContext(ConnectModalContext)
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider')
  }
  return context
}
