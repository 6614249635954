import { Typography } from 'antd'
import { styled } from 'styled-components'

import { IStyledComponent } from '../types'
import createComponentStyles from '../createComponentStyles'

const StyledSpan = styled(Typography.Text)<IStyledComponent>(
  createComponentStyles,
)

const Span = (props: IStyledComponent) => <StyledSpan {...props} />

export default Span
