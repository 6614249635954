import { Typography } from 'antd'
import { BaseType } from 'antd/es/typography/Base'
import { TitleProps as OriginalTitleProps } from 'antd/es/typography/Title'
import { styled } from 'styled-components'
import { useTheme } from '../../../../app/theme/useTheme'
import { forwardRef } from 'react'

declare const TITLE_ELE_LIST: readonly [1, 2, 3, 4, 5]
type Type = BaseType | 'lightSecondary'
type Level = (typeof TITLE_ELE_LIST)[number]
type Light = boolean | undefined

interface StyledTitleProps
  extends Omit<OriginalTitleProps, 'type' | 'level' | 'light'> {
  type?: Type
  level?: Level
  $light?: Light
}

interface FontStyleParams {
  fontWeight: number
  fontWeightLight: number
}

const getTitleStyles = ({ type, level, $light }: StyledTitleProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const theme = useTheme()

  const fontStylesObj: Record<string, FontStyleParams> = {
    heading1: {
      fontWeight: theme.fontWeightExtraBold,
      fontWeightLight: theme.fontWeightSemiBold,
    },
    heading2: {
      fontWeight: theme.fontWeightExtraBold,
      fontWeightLight: theme.fontWeightSemiBold,
    },
    heading3: {
      fontWeight: theme.fontWeightBold,
      fontWeightLight: theme.fontWeightSemiBold,
    },
    heading4: {
      fontWeight: theme.fontWeightBold,
      fontWeightLight: theme.fontWeightSemiBold,
    },
    heading5: {
      fontWeight: theme.fontWeightBold,
      fontWeightLight: theme.fontWeightSemiBold,
    },
    default: {
      fontWeight: theme.fontWeightExtraBold,
      fontWeightLight: theme.fontWeightSemiBold,
    },
  }

  const { fontWeightLight, fontWeight } = level
    ? fontStylesObj[`heading${level}`]
    : fontStylesObj.default

  return {
    fontFamily: 'Plus Jakarta Sans',
    '&&': {
      fontWeight: $light ? fontWeightLight : fontWeight,
    },
    ...(type === 'secondary' && {
      '&&&': { color: theme.colorTextSecondary },
    }),
    ...(type === 'lightSecondary' && {
      '&&&': { color: theme.colorTextDisabled },
    }),
  }
}

const StyledTitle = styled(Typography.Title)<StyledTitleProps>(getTitleStyles)

const Title = forwardRef<HTMLHeadingElement, StyledTitleProps>((props, ref) => (
  <StyledTitle {...props} ref={ref} />
))

Title.displayName = 'Title'

export default Title
