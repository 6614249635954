import { useAccount } from '@account-kit/react'
import { EMBEDDED_ACCOUNT_TYPE } from '../constants/constants.ts'
import { useMemo } from 'react'
import { useAccount as useExternalAccount } from 'wagmi'

export const useCurrentAccount = () => {
  const { address: externalAccount } = useExternalAccount()
  const { address: embeddedAccount } = useAccount({
    type: EMBEDDED_ACCOUNT_TYPE,
  })

  const currentAccount = useMemo(
    () => (externalAccount ? externalAccount : embeddedAccount),
    [embeddedAccount, externalAccount],
  )

  return { embeddedAccount, externalAccount, currentAccount }
}
