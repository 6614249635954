import { useAccount, useConnect } from 'wagmi'
import { useEffect, useState } from 'react'

export const useWalletIcon = () => {
  const { connector: activeConnector, address } = useAccount()
  const { connectors } = useConnect()
  const [icon, setIcon] = useState('')

  useEffect(() => {
    if (address) {
      const icon = connectors.find(
        (connector) => connector.id === activeConnector?.id,
      )
      if (icon?.icon) {
        setIcon(icon.icon)
      }
    }
  }, [activeConnector?.id, address, connectors])

  return icon
}
