import { ConfigProvider } from 'antd'
import { FC, ReactNode } from 'react'
import { config } from './config'
import CustomThemeProvider from './CustomThemeProvider'

interface IThemeProvider {
  children: ReactNode
}

const ThemeProvider: FC<IThemeProvider> = ({ children }) => {
  return (
    <ConfigProvider theme={config}>
      <CustomThemeProvider>{children}</CustomThemeProvider>
    </ConfigProvider>
  )
}

export default ThemeProvider
