import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import enUS from 'antd/es/locale/en_US'
import transEN from '../../shared/locales/en.json'

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: 'gb',
  },
]

export const defaultLang = allLangs[0]

const resources = {
  en: {
    translation: transEN,
  },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('i18nextLng') || defaultLang.value,
    fallbackLng: defaultLang.value,
    interpolation: {
      escapeValue: false,
    },
    parseMissingKeyHandler: (key) => `${key}`,
  })

export default i18n
