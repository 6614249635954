import { initializeApp } from 'firebase/app'
import {
  confirmPasswordReset,
  getAuth,
  getMultiFactorResolver,
  multiFactor,
  MultiFactorError,
  sendPasswordResetEmail,
  TotpMultiFactorGenerator,
  TotpSecret,
} from 'firebase/auth'
import { GoogleAuthProvider } from 'firebase/auth'

const firebaseConfig = {
  apiKey: import.meta.env.VITE_API_KEY,
  authDomain: import.meta.env.VITE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_APP_ID,
  measurementId: import.meta.env.VITE_MEASUREMENT_ID,
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
auth.useDeviceLanguage()

const googleAuthProvider = new GoogleAuthProvider()

export { auth }
export { googleAuthProvider }

export const forgotPassword = async (email: string) => {
  return await sendPasswordResetEmail(auth, email)
}

export const confirmThePasswordReset = async (
  oobCode: string,
  newPassword: string,
) => {
  if (!oobCode && !newPassword) return

  return await confirmPasswordReset(auth, oobCode, newPassword)
}

export const verifyTwoFactorCode = async (
  authCode: string,
  loginError: MultiFactorError,
) => {
  const mfaResolver = getMultiFactorResolver(auth, loginError)
  const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(
    mfaResolver.hints[0].uid,
    authCode,
  )

  await mfaResolver.resolveSignIn(multiFactorAssertion)
}

export const enrollTwoFactorAuth = async (
  totpSecret: TotpSecret,
  authCode: string,
) => {
  const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(
    totpSecret,
    authCode,
  )
  await multiFactor(auth.currentUser!).enroll(multiFactorAssertion, 'TOTP')
}
