import { Typography } from 'antd'
import { styled } from 'styled-components'
import { forwardRef } from 'react'
import { IStyledComponent } from '../types'
import createComponentStyles from '../createComponentStyles'

const StyledText = styled(Typography.Paragraph)<IStyledComponent>(
  createComponentStyles,
)

const Text = forwardRef<HTMLHeadingElement, IStyledComponent>((props, ref) => (
  <StyledText {...props} ref={ref} />
))

Text.displayName = 'Text'

export default Text
