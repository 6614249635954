import {
  List as AntList,
  Avatar as AvatarAnt,
  CardProps,
  Col,
  ListProps,
  Tag,
  TagProps,
} from 'antd'
import styled from 'styled-components'
import { useTheme } from '../../../../app/theme/useTheme'
import { ListItemTypeProps } from 'antd/es/list/Item'
import { CustomCard } from '../../../../shared/components/CustomCard'

export const ListStyled = styled(AntList)<ListProps<ListItemTypeProps>>(() => {
  const theme = useTheme()
  return {
    '&&&.ant-list': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',

      '>div:first-child': {
        flex: 1,
      },
    },
    '&&& .ant-pagination li': {
      marginInlineEnd: theme.marginXS,
    },
    '&&& .ant-pagination': {
      display: 'flex',

      '.ant-pagination-total-text': {
        order: 2,
        marginLeft: 'auto',
        display: 'flex',
        alignItems: 'center',
      },
    },
  }
})

export const Item = styled(AntList.Item)(() => {
  const theme = useTheme()

  return {
    '&&&&&.ant-list-item': {
      borderBlockEnd: 'none',
      padding: theme.padding,
      paddingInline: 0,
      paddingBlockStart: 0,
    },
    '.ant-list-pagination': {
      marginBlockStart: theme.padding,
    },
  }
})

interface StyledTagProps extends TagProps {
  disableMinWidth?: boolean
  borderColor?: string
}

export const StyledTag = styled(Tag)<StyledTagProps>(({
  disableMinWidth,
  borderColor,
}) => {
  const theme = useTheme()

  return {
    borderRadius: theme.borderRadius,
    border: borderColor ? `1px solid ${borderColor} !important` : undefined,
    minWidth: disableMinWidth ? undefined : 64,
    paddingTop: theme.paddingXXS / 2,
    paddingBottom: theme.paddingXXS / 2,
    marginRight: 0,
  }
})

export const ContractContainer = styled(Col)(() => {
  const theme = useTheme()
  return {
    '&&&': {
      paddingBottom: theme.paddingXXL,
      display: 'flex',
      flexDirection: 'column',
    },
  }
})

interface CardInfoStyledProps extends CardProps {
  $shadow?: boolean
  $checkList?: boolean
  $hourly?: boolean
  error?: string
}

export const CardStyled = styled(CustomCard)<CardInfoStyledProps>(({
  $checkList,
  $hourly,
}) => {
  const theme = useTheme()
  return {
    width: '100%',
    '& .ant-card-body': {
      padding: $hourly ? theme.paddingLG : theme.paddingMD,
      paddingInline: $hourly ? undefined : theme.paddingXL,
      ...($checkList && { paddingBottom: theme.paddingXL }),
    },
  }
})

export const CardStyledWidget = styled(CustomCard)<CardInfoStyledProps>(() => {
  const theme = useTheme()
  return {
    border: 'none',
    '& .ant-card-body': {
      paddingInline: theme.paddingXL,
      paddingBottom: theme.paddingXL,
      paddingTop: '28px',
    },
  }
})

export const MainContainerCard = styled(CardStyled)(() => {
  const theme = useTheme()
  return {
    border: 'none',
    '& .ant-card-body': {
      paddingBlock: theme.paddingXL,
    },
  }
})

export const CardPayment = styled(CardStyled)((error) => {
  const theme = useTheme()
  return {
    backgroundColor: theme.colorBgBase,
    border: `1px solid ${theme.colorBorderTertiary}`,
    borderColor: error.error ? theme.colorError : undefined,
    boxShadow: 'none',
    borderRadius: theme.borderRadiusXL,

    '&& .ant-card-body': {
      paddingInline: 0,
      padding: theme.padding,
    },
  }
})

export const PaymentIcon = styled(AvatarAnt)(() => {
  const theme = useTheme()
  return {
    backgroundColor: 'inherit',
    border: `1px solid ${theme.colorBorder}`,
    width: 36,
    height: 36,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
})

export const MilestoneDetailsCard = styled(CustomCard)<CardInfoStyledProps>(
  () => {
    const theme = useTheme()
    return {
      boxShadow: 'none',
      '& .ant-card-body': {
        paddingInline: theme.padding,
      },
    }
  },
)
