import React, { useEffect } from 'react'
import Modal from '../../../../../../../../../../../../shared/components/Modal'
import Title from '../../../../../../../../../../../../shared/components/Typography/Title'
import Text from '../../../../../../../../../../../../shared/components/Typography/Text'
import { Col, Image, Row } from 'antd'
import { useTheme } from '../../../../../../../../../../../../app/theme/useTheme.tsx'
import useLocales from '../../../../../../../../../../../../shared/hooks/useLocales.ts'
import alchemyIcon from '../../../../../../../../../../../../shared/assets/al-logo.svg'
import { Spinner } from '../../../../../../../../../../../../shared/components/icons/Spinner.tsx'
import { useSignerStatus } from '@account-kit/react'

interface IEmbeddedWalletEmailModal {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export const EmbeddedWalletEmailModal: React.FC<IEmbeddedWalletEmailModal> = ({
  setIsOpen,
  isOpen,
}) => {
  const theme = useTheme()
  const { translate } = useLocales()
  const signerStatus = useSignerStatus()

  useEffect(() => {
    if (signerStatus.isConnected) setIsOpen(false)
  }, [setIsOpen, signerStatus.isConnected])

  return (
    <Modal
      open={isOpen}
      setOpen={setIsOpen}
      onCancel={() => setIsOpen(false)}
      handleOk={undefined}
      width={480}
      footer={null}
      cta={''}
      maskClosable={signerStatus.isConnected}
      closable={signerStatus.isConnected}
    >
      <Col
        style={{
          gap: theme.paddingXL,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Row style={{ gap: '8px' }} justify="center">
          <Title level={2} style={{ textAlign: 'center' }}>
            {translate(`contracts_details.check_list.wallet.modal_email.title`)}
          </Title>
          <Text style={{ textAlign: 'center' }} type="secondary">
            {translate(
              `contracts_details.check_list.wallet.modal_email.description`,
            )}
          </Text>
        </Row>
        <Spinner />
        <Row
          align="middle"
          justify="center"
          style={{ marginTop: theme.padding }}
        >
          <Image src={alchemyIcon} preview={false} />
        </Row>
      </Col>
    </Modal>
  )
}
