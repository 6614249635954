import { useTranslation } from 'react-i18next'
import { allLangs, defaultLang } from '../../app/config/i18n'

export default function useLocales() {
  const { i18n, t: translate } = useTranslation()

  const langStorage = localStorage.getItem('i18nextLng')

  const currentLang =
    allLangs.find((_lang) => _lang.value === langStorage) || defaultLang

  const handleChangeLanguage = async (language: string) => {
    await i18n.changeLanguage(language)
    localStorage.setItem('i18nextLng', language)
  }

  return {
    onChangeLang: handleChangeLanguage,
    translate: (text: string, options?: Record<string, string>) =>
      translate(text, options),
    currentLang,
    allLangs,
  }
}
