export const Spinner = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60px"
      height="60px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        r="32"
        strokeWidth="6" /* Stroke thickness */
        stroke="black" /* Ant Design blue color */
        strokeDasharray="80 50" /* Control the length of the visible part of the circle */
        fill="none"
        strokeLinecap="round"
        transform="rotate(271.842 50 50)"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        />
      </circle>
    </svg>
  )
}
