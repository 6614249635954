import { notification } from 'antd'
import { useCallback } from 'react'
import { StyledMessage } from './StyledMessage'

type NotificationType = 'success' | 'info' | 'warning' | 'error'

interface openNotificationType {
  message: string
  description?: string
  type: NotificationType
}

const useNotification = () =>
  useCallback(({ message, description, type }: openNotificationType) => {
    notification[type]({
      message: <StyledMessage>{message}</StyledMessage>,
      description,
    })
  }, [])

export default useNotification
