import { Button as AntButton, ButtonProps } from 'antd'
import { ButtonType as BaseButtonType } from 'antd/lib/button'
import { ButtonGroupProps } from 'antd/es/button/button-group'
import { forwardRef } from 'react'
import styled, { CSSProperties } from 'styled-components'
import { useTheme } from '../../../app/theme/useTheme'

interface CustomButtonProps extends Omit<ButtonProps, 'type'> {
  $minWidth?: number
  $styled?: CSSProperties
  type?: ButtonType
}

type ButtonType = BaseButtonType | 'branded'

type CompoundedComponent = React.ForwardRefExoticComponent<
  CustomButtonProps & React.RefAttributes<HTMLButtonElement | HTMLAnchorElement>
> &
  ButtonGroupProps

type SpecialButtonType = 'default' | 'branded'

const isSpecialButtonType = (type: any): type is SpecialButtonType => {
  return type === 'default' || type === 'branded'
}
const StyledButton = styled(AntButton).withConfig({
  shouldForwardProp: (prop) => prop !== '$minWidth' && prop !== '$styled',
})<CustomButtonProps>(({ type = 'default', $minWidth, $styled }) => {
  const theme = useTheme()

  const commonStyles = {
    ...$styled,
    boxShadow: 'none',
    ...($minWidth && { minWidth: $minWidth }),

    '&&&:disabled': {
      border: 0,
    },
  }

  const specialStyles: { [key in SpecialButtonType]: any } = {
    branded: {
      backgroundColor: theme.colorBrandedButton,
      color: theme.colorText,
      '&&&:hover': {
        backgroundColor: theme.hoverBrandedButton,
        color: theme.colorText,
      },
      '&:active': {
        backgroundColor: theme.activeBrandedButton,
      },
      '&:focus': {
        backgroundColor: theme.colorBrandedButton,
      },
      '&:disabled': {
        backgroundColor: theme.colorBgContainerDisabled,
        color: theme.colorTextDisabled,
        pointerEvents: 'none' as React.CSSProperties['pointerEvents'],
      },
    },
    default: {
      borderColor: theme.colorBorder,
      '&&&:disabled': {
        border: `1px solid ${theme.colorBorder}`,
      },
    },
  }

  return isSpecialButtonType(type)
    ? { ...commonStyles, ...(specialStyles[type] || {}) }
    : { ...commonStyles }
})

// eslint-disable-next-line react/display-name
const Button: CompoundedComponent = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  CustomButtonProps
>(({ children, ...rest }, ref) => {
  return (
    <StyledButton ref={ref} {...rest}>
      {children}
    </StyledButton>
  )
})

export default Button
