import { alchemy, polygonAmoy } from '@account-kit/infra'
import { cookieStorage, createConfig } from '@account-kit/react'

export const chain = polygonAmoy

const transport = alchemy({ apiKey: import.meta.env.VITE_APP_ALCHEMY_API_KEY })

export const configAlchemy = createConfig({
  transport,
  signerConnection: {
    rpcUrl: `${import.meta.env.VITE_API}${import.meta.env.VITE_APP_ALCHEMY_API_ADDRESS}`,
  },
  sessionConfig: {
    expirationTimeMs: 3600000,
  },
  chain,
  storage: cookieStorage,
  policyId: import.meta.env.VITE_APP_ALCHEMY_GAS_MANAGER_POLICY_ID,
})
