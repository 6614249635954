import { AuthStore } from './types'

export const isAuthLoadingSelector = (store: AuthStore) => store.isLoading
export const errorAuthSelector = (store: AuthStore) => store.error
export const userSelector = (store: AuthStore) => store.user
export const tokenSelector = (store: AuthStore) => store.accessToken
export const loginFnSelector = (store: AuthStore) => store.login
export const loginWithGoogleSelector = (store: AuthStore) => store.googleLogin
export const logOutSelector = (store: AuthStore) => store.logOut
export const refreshSelector = (store: AuthStore) => store.setUserAfterRefresh
export const afterOnboardingSelector = (store: AuthStore) =>
  store.setUserAfterOnboarding
export const updateUserRoleSelector = (store: AuthStore) => store.updateUserRole
export const updateUserNamesSelector = (store: AuthStore) =>
  store.updateUserNames
export const updateUserCountrySelector = (store: AuthStore) =>
  store.updateUserCountry
export const updateUserIsVerifiedSelector = (store: AuthStore) =>
  store.updateUserIsVerified
export const setLoadingSelector = (store: AuthStore) => store.setLoading
export const sendVerificationSelector = (store: AuthStore) =>
  store.sendVerification
export const signUpSelector = (store: AuthStore) => store.signUp
export const applyActionSelector = (store: AuthStore) => store.applyAction
