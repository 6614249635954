const themeColors = {
  gray: [
    '#F6F6F6', // Gray 1
    '#DBDBDB', // Gray 2
    '#C4C4C4', // Gray 3
    '#ACACAC', // Gray 4
    '#959595', // Gray 5
    '#7D7D7D', // Gray 6
    '#616161', // Gray 7
    '#4A4A4A', // Gray 8
    '#373737', // Gray 9
    '#262626', // Gray 10
  ],
  lawngreen: [
    '#EFFFE0', // Lawngreen 1
    '#DCFFB9', // Lawngreen 2
    '#C5FE69', // Lawngreen 3
    '#BCF93D', // Lawngreen 4
    '#B0F127', // Lawngreen 5
    '#7FCC1B', // Lawngreen 6
    '#6EB216', // Lawngreen 7
    '#599E00', // Lawngreen 8
    '#4A880C', // Lawngreen 9
    '#314E23', // Lawngreen 10
  ],
}

export default themeColors
