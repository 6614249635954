import { useRoutes } from 'react-router-dom'
import { lazy } from 'react'
import { Loadable } from './Loadable'
import { wrapUseRoutes } from '@sentry/react'
import EmbeddedEmailAuth from '../pages/embeddedEmailAuth'
import { ConnectModalProvider } from '../shared/contexts/walletModalContext'
import AuthGuard from '../guards/AuthGuard'
import { ConnectWalletModal } from '../shared/components/ConnectWalletModal'

const useSentryRoutes = wrapUseRoutes(useRoutes)

const Router = () =>
  useSentryRoutes([
    {
      path: 'auth/*',
      element: <AuthRoutes />,
    },
    {
      path: '/*',
      element: <DashboardRoutes />,
    },
    {
      path: '/setup-project/*',
      element: <StepsRoutes />,
    },
    {
      path: '/documents',
      element: <PolicyDocuments />,
    },
    {
      path: '/faucet',
      element: (
        <AuthGuard>
          <ConnectModalProvider>
            <Faucet />
            <ConnectWalletModal />
          </ConnectModalProvider>
        </AuthGuard>
      ),
    },
    {
      path: '/embedded-email-auth',
      element: <EmbeddedEmailAuth />,
    },
    {
      path: '/profile/*',
      element: <ProfileRoutes />,
    },
    {
      path: '/components',
      element: <ComponentsPage />,
    },
  ])

export default Router

const AuthRoutes = Loadable(lazy(() => import('./AuthRoutes')))
const DashboardRoutes = Loadable(lazy(() => import('./DashboardRoutes')))
const StepsRoutes = Loadable(lazy(() => import('./StepsRoutes')))
const PolicyDocuments = Loadable(
  lazy(() => import('../pages/PolicyDocumentsPage')),
)
const Faucet = Loadable(lazy(() => import('../pages/FaucetPage')))
const ProfileRoutes = Loadable(lazy(() => import('./ProfileRoutes')))
const ComponentsPage = Loadable(
  lazy(() => import('../pages/ComponentsPage/index')),
)
