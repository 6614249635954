import { useLogout } from '@account-kit/react'

export const useLogoutEmbedded = () => {
  const { logout } = useLogout()

  const handleLogout = () => {
    logout()
    localStorage.removeItem('alchemy-signer-session')
    localStorage.removeItem('alchemy-signer-session:temporary')
  }

  return handleLogout
}
