import { Spin } from 'antd'
import { FC, ReactNode } from 'react'
import { isAuthLoadingSelector } from '../../services/authStore/selectors'
import { useAuthStore } from '../../services/authStore'
import styled from 'styled-components'
import { isLoadingSelector } from '../../services/appStatus/selectors'
import { useAppStatus } from '../../services/appStatus'

interface ILoader {
  children: ReactNode
}

const CenteredLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 999999;
`

export const StyledSpin = styled(Spin)({
  '&&&.ant-spin': {
    position: 'relative',
    paddingTop: 24,
    paddingBottom: 24,
    zIndex: 99999,
  },
})

const Loader: FC<ILoader> = ({ children }) => {
  const authLoading = useAuthStore(isAuthLoadingSelector)
  const loading = useAppStatus(isLoadingSelector)

  return (
    <>
      {(authLoading || loading) && (
        <CenteredLoaderContainer>
          <StyledSpin spinning={authLoading || loading} size="large" />
        </CenteredLoaderContainer>
      )}
      {children}
    </>
  )
}

export default Loader
