import { useEffect } from 'react'
import { useGetCurrentUserLazyQuery } from '../../../app/graphQl/generated/graphql'
import { useAppStatus } from '../../../shared/services/appStatus'
import { setAppLoadingSelector } from '../../../shared/services/appStatus/selectors'
import useNotification from '../../../shared/hooks/useNotification'

const useGetUserLazy = () => {
  const setLoading = useAppStatus(setAppLoadingSelector)
  const openNotification = useNotification()

  const [getUser, { loading, data }] = useGetCurrentUserLazyQuery({
    fetchPolicy: 'no-cache',
    onError: (error) => {
      !error.message.includes('USER IS NOR FOUND') &&
        openNotification({
          message: error.message,
          type: 'error',
        })
      setLoading(false)
    },
    onCompleted: () => setLoading(false),
  })

  useEffect(() => {
    setLoading(loading)
  }, [loading, setLoading])

  return { data: data?.findMyUser, getUser }
}

export default useGetUserLazy
