import { Image, Row } from 'antd'
import { useTheme } from '../../app/theme/useTheme.tsx'
import Title from '../../shared/components/Typography/Title'
import Text from '../../shared/components/Typography/Text'
import { AlchemyConnected } from '../../shared/components/icons/blockchainWallets/AlchemyConnected.tsx'
import Card from 'antd/es/card/Card'
import alchemyIcon from '../../shared/assets/al-logo.svg'

const EmbeddedEmailAuth = () => {
  const theme = useTheme()
  return (
    <Row
      style={{
        backgroundColor: theme.colorBgLayout,
        height: '100vh',
      }}
      justify="center"
    >
      <Card
        style={{
          backgroundColor: 'white',
          width: '463px',
          height: '297px',
          paddingTop: '8px',
          paddingLeft: theme.paddingXL,
          paddingRight: theme.paddingXL,
          marginTop: '120px',
        }}
      >
        <Row align="middle" justify="center" style={{ gap: theme.paddingMD }}>
          <AlchemyConnected />
          <Row style={{ marginBottom: theme.paddingMD, gap: theme.paddingXS }}>
            <Title
              level={2}
              style={{
                letterSpacing: '0.24px',
                whiteSpace: 'nowrap',
                textAlign: 'center',
              }}
            >
              Your wallet is connected now!
            </Title>
            <Text size="lg" type="secondary" style={{ textAlign: 'center' }}>
              You can close this window and go back to Midcontract
            </Text>
          </Row>
          <Image src={alchemyIcon} preview={false} />
        </Row>
      </Card>
    </Row>
  )
}

export default EmbeddedEmailAuth
