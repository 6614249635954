import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  split,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { auth } from '../firebase'
import { BatchHttpLink } from '@apollo/client/link/batch-http'

const graphqlEndpoint = `${import.meta.env.VITE_API}${import.meta.env.VITE_GRAPHQL_ENDPOINT}`

// Batch HTTP Link for batched requests
const batchHttpLink = new BatchHttpLink({
  uri: graphqlEndpoint,
  batchMax: 5,
  batchInterval: 20,
})

// Standard HTTP Link for non-batched requests
const standardHttpLink = createHttpLink({
  uri: graphqlEndpoint,
})

// Authentication Link
const authLink = setContext(async (_, { headers }) => {
  const user = auth.currentUser
  if (user) {
    const token = await user.getIdToken()
    const transactionId = localStorage.getItem('@midcontract:transaction_id')
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
        'X-Transaction-ID': transactionId,
      },
    }
  } else {
    return { headers }
  }
})

// Split Link to selectively disable batching
const link = split(
  ({ query }) => {
    const definition = query.definitions[0]
    // Ensure the definition is an OperationDefinitionNode before accessing 'operation'
    if (definition.kind === 'OperationDefinition') {
      const operation = definition
      return (
        operation.operation === 'mutation' &&
        operation.name?.value === 'registerUser'
      )
    }
    return false
  },
  standardHttpLink, // Use standard HTTP link (no batching) for specific queries
  batchHttpLink, // Use batching for everything else
)

// Apollo Client Setup
const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache(),
})

export default client
