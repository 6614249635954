import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { devtools } from 'zustand/middleware'
import { AppStatus } from './types'

const initialState = {
  isLoading: false,
  // error: "",
}

export const useAppStatus = create<AppStatus>()(
  devtools(
    immer((set) => ({
      ...initialState,
      setLoading: (loading: boolean) =>
        set((state) => {
          state.isLoading = loading
        }),
      // setError: (message: string, errorNotification?: (message: string) => void) => {
      //   set((state) => {
      //     state.error = message;
      //   });
      //   errorNotification && errorNotification(message);
      // },
    })),
  ),
)
