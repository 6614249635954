import { useEffect, useMemo, useState } from 'react'
import Modal from '../Modal'
import { Col, Row } from 'antd'
import { useTheme } from '../../../app/theme/useTheme.tsx'
import Title from '../Typography/Title'
import Text from '../Typography/Text'
import ConnectedWallet from '../../../pages/dashboard/ContractsPage/ContractsList/pages/ContractsDetails/components/Checklist/components/Steps/AddWallets/components/connectedWallet.tsx'
import { useWalletNumber } from '../../hooks/useWalletNumber.tsx'
import { useParams } from 'react-router-dom'
import { useAuthStore } from '../../services/authStore'
import { userSelector } from '../../services/authStore/selectors.ts'
import { useCurrentAccount } from '../../hooks/useCurrentAccount.tsx'
import { Steps } from '../../../pages/dashboard/ContractsPage/ContractsList/pages/ContractsDetails/components/Checklist/CheckList.styled.ts'
import Span from '../Typography/Span'
import AlchemyBlock from '../../../shared/assets/alchemyBlock.svg'
import WalletsBlock from '../../../shared/assets/walletsBlock.svg'
import { useGetUserWalletsQuery } from '../../../app/graphQl/generated/graphql.ts'
import { WALLET_TYPES } from '../../constants/constants.ts'
import { useConnectWalletModal } from '../../hooks/useConnectWalletModal.tsx'
import { DividerIcon } from '../icons/DividerIcon.tsx'
import { CheckOutlined } from '@ant-design/icons'
import { DividerIconShort } from '../icons/DividerIconShort.tsx'

export const ConnectWalletModal = () => {
  const { isOpen, setIsOpen, isHeader, setIsHeader } = useConnectWalletModal()
  const { contractId } = useParams()
  const { id } = useAuthStore(userSelector)
  const [isWrongWallet, setIsWrongWallet] = useState<boolean>(false)
  const {
    contractWallet,
    loading: allWalletsLoading,
    refetchWallets,
  } = useWalletNumber(contractId, id)
  const { data, loading, refetch } = useGetUserWalletsQuery({
    fetchPolicy: 'no-cache',
  })
  const theme = useTheme()
  const { currentAccount } = useCurrentAccount()
  const savedEmbeddedWallet = useMemo(
    () =>
      data?.getUserWallets.filter(
        (wallet) => wallet.walletType === WALLET_TYPES.Embedded,
      ),
    [data],
  )

  useEffect(() => {
    if (currentAccount && currentAccount !== contractWallet?.userWallet?.wallet)
      setIsWrongWallet(true)
  }, [currentAccount, contractWallet])

  useEffect(() => {
    if (isOpen) {
      if (refetchWallets) {
        void refetchWallets()
      }
      if (refetch) {
        void refetch()
      }
    }
  }, [isOpen, refetch, refetchWallets])

  useEffect(() => {
    if (!isOpen && !currentAccount) {
      setIsWrongWallet(false)
    }
  }, [isOpen, currentAccount])

  return (
    <Modal
      open={isOpen}
      loading={loading || allWalletsLoading}
      setOpen={setIsOpen}
      onCancel={() => {
        setIsOpen(false)
        setIsHeader(false)
      }}
      handleOk={undefined}
      width={482}
      footer={null}
      cta={''}
    >
      <Col span={24}>
        <Title level={2} style={{ textAlign: 'left' }}>
          {isWrongWallet ? 'Connect your contract wallet' : 'Connect wallet'}
        </Title>
        <Text
          size="lg"
          type="secondary"
          style={{ textAlign: 'left', marginTop: theme.paddingXS }}
        >
          {isHeader
            ? 'Choose an option below to proceed'
            : isWrongWallet
              ? 'Please connect the wallet you’ve previously used for this contract type.'
              : 'You must use the wallet previously selected by you as the payment method for this contract'}
        </Text>
        {isHeader ? (
          <Row style={{ marginTop: theme.paddingLG, gap: theme.paddingXS }}>
            {savedEmbeddedWallet && savedEmbeddedWallet?.length > 0 && (
              <ConnectedWallet
                icon={AlchemyBlock}
                walletType="EMBEDDED"
                title="embedded"
                wallet={savedEmbeddedWallet[0]?.wallet || ''}
              />
            )}
            <ConnectedWallet icon={WalletsBlock} title="Use external wallet" />
          </Row>
        ) : (
          <>
            <Row style={{ marginTop: theme.paddingLG, gap: theme.paddingXS }}>
              {isWrongWallet && (
                <Row style={{ gap: theme.margin, marginBottom: theme.margin }}>
                  {currentAccount ? (
                    <Steps
                      icon={
                        <Span color="white" size="sm">
                          1
                        </Span>
                      }
                      size={theme.iconSizeButton}
                    />
                  ) : (
                    <Steps
                      $done
                      icon={<CheckOutlined />}
                      size={theme.iconSizeButton}
                    />
                  )}
                  <Text size="lg" strong>
                    Disconnect current wallet
                  </Text>
                </Row>
              )}
            </Row>
            <Row
              style={{
                flexWrap: 'nowrap',
              }}
            >
              {isWrongWallet && (
                <Row style={{ paddingLeft: '10px' }}>
                  {currentAccount ? <DividerIcon /> : <DividerIconShort />}
                </Row>
              )}
              <Row
                style={{
                  width: '100%',
                  height: '100%',
                  paddingLeft: isWrongWallet ? '20px' : undefined,
                }}
              >
                {(!isWrongWallet || (isWrongWallet && currentAccount)) && (
                  <ConnectedWallet
                    walletType={
                      !currentAccount
                        ? contractWallet?.userWallet?.walletType
                        : ''
                    }
                    icon={
                      !currentAccount ? contractWallet?.userWallet?.icon : ''
                    }
                    wallet={
                      !currentAccount ? contractWallet?.userWallet?.wallet : ''
                    }
                    loading={!currentAccount && loading}
                  />
                )}
              </Row>
            </Row>
            {isWrongWallet && (
              <Row style={{ marginTop: theme.paddingLG }}>
                <Row style={{ gap: theme.margin, marginBottom: theme.margin }}>
                  <Steps
                    disabled={!!currentAccount}
                    icon={
                      <Span
                        color={currentAccount ? 'black' : 'white'}
                        size="sm"
                      >
                        2
                      </Span>
                    }
                    size={theme.iconSizeButton}
                  />
                  <Text size="lg" strong>
                    Connect correct wallet
                  </Text>
                </Row>
                <Row style={{ width: '100%', paddingLeft: theme.paddingXL }}>
                  <ConnectedWallet
                    walletType={contractWallet?.userWallet?.walletType}
                    icon={contractWallet?.userWallet?.icon}
                    wallet={contractWallet?.userWallet?.wallet}
                    loading={loading}
                    showContractWallet
                  />
                </Row>
              </Row>
            )}
          </>
        )}
      </Col>
    </Modal>
  )
}
