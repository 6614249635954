function path(root: string, subLink: string) {
  return `${root}${subLink}`
}

const ROOTS_AUTH = '/auth'

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  signIn: path(ROOTS_AUTH, '/signIn'),
  twoFactorAuth: path(ROOTS_AUTH, '/signIn/two-factor-auth'),
  signUp: path(ROOTS_AUTH, '/signUp'),
  choose_role: path(ROOTS_AUTH, '/signUp/choose-role'),
  introduce: path(ROOTS_AUTH, '/signUp/choose-role/introduce'),
  forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
}

const ROOTS_VERIFY = path(ROOTS_AUTH, '/verify')

export const PATH_VERIFY = {
  root: ROOTS_VERIFY,
  newPassword: path(ROOTS_VERIFY, '/new-password'),
  success: path(ROOTS_VERIFY, '/success'),
  decline: path(ROOTS_VERIFY, '/decline'),
}

const ROOTS_DASHBOARD = '/'

export const ROOTS_PATH_CONTRACT = path(ROOTS_DASHBOARD, 'contracts')

export const PATH_CONTRACT = {
  root: ROOTS_PATH_CONTRACT,
  pending: path(ROOTS_PATH_CONTRACT, '/pending'),
  active: path(ROOTS_PATH_CONTRACT, '/active'),
  archive: path(ROOTS_PATH_CONTRACT, '/archive'),
}

export const PATH_PAYMENTS = '/payments'

export const RETURN_REQUEST = '/return-requests'
export const DISPUTE = '/dispute'

const ROOTS_SETUP = path(ROOTS_DASHBOARD, 'setup-project')

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  newContracts: path(ROOTS_DASHBOARD, 'new-contract'),
  setupProject: ROOTS_SETUP,
  stepOne: path(ROOTS_SETUP, '/step_one'),
  stepTwo: path(ROOTS_SETUP, '/step_two'),
  stepThree: path(ROOTS_SETUP, '/step_three'),
  perProject: path(ROOTS_SETUP, '/step_three/per_project'),
  hourlyRate: path(ROOTS_SETUP, '/step_three/hourly_rate'),
  reviewContract: path(ROOTS_SETUP, '/review_contract'),
  success: path(ROOTS_SETUP, '/success'),
}

export const PATH_NEW_CONTRACT = '/new-contract'
export const PATH_POLICY_DOCUMENTS = '/documents'
export const PATH_FAUCET = '/faucet'

export const PATH_PROFILE = {
  profileInfo: '/profile/info',
  companyInfo: '/profile/company',
  agencyInfo: '/profile/agency',
  loginSecurity: '/profile/security',
}
