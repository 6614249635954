import styled from 'styled-components'
import { CardProps } from 'antd/es/card'
import { Card } from 'antd'
import { useTheme } from '../../../app/theme/useTheme.tsx'

interface CardStyledProps extends CardProps {
  dinamicShadow?: boolean
}

export const CustomCard = styled(Card)<CardStyledProps>(({ dinamicShadow }) => {
  const theme = useTheme()
  const generalStyle = {
    backgroundColor: theme.tileBackgroundColor,
    borderRadius: theme.tileBorderRadius,
    boxShadow: theme.tileBoxShadow,
  }
  const dinamicShadowStyles = {
    transform: 'translateY(0)',
    transition: 'all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1)',
    '&:hover': {
      boxShadow:
        'rgba(38, 38, 38, 0.03) 0px 1px 2px, rgba(38, 38, 38, 0.07) 0px 8px 10px',
      transform: 'translateY(-4px)',
      transition: 'all 0.8s cubic-bezier(0.25, 0.8, 0.25, 1)',
    },
    '&&&:focus, &:active': {
      boxShadow:
        'rgba(27, 26, 26, 0.05) 0 0 0 2px, rgba(38, 38, 38, 0.03) 0px 1px 2px, rgba(38, 38, 38, 0.07) 0px 8px 10px',
      backgroundColor: '#FAFAFA',
      transform: 'translateY(4px)',
      transition: 'transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1)',
    },
  }
  return !dinamicShadow
    ? { ...generalStyle }
    : { ...generalStyle, ...dinamicShadowStyles }
})
