import { ComponentProps, ComponentType, Suspense } from 'react'
import Loader from '../shared/components/Loader'

export const Loadable = <T extends ComponentType<any>>(Component: T) => {
  const LoadableComponent = (props: ComponentProps<T>) => (
    <Suspense fallback={<Loader> </Loader>}>
      <Component {...props} />
    </Suspense>
  )

  LoadableComponent.displayName = `Loadable(${
    (Component.displayName ?? Component.name) || 'Component'
  })`
  return LoadableComponent
}
