import { Avatar as AvatarAnt, Row } from 'antd'
import styled from 'styled-components'
import { useTheme } from '../../../../../../../../app/theme/useTheme'
import { AvatarProps } from 'antd/es/avatar'
import { CardStyled } from '../../../../List.styled'
import { CustomCard } from '../../../../../../../../shared/components/CustomCard'

interface ExtendedAvatarProps extends AvatarProps {
  disabled?: boolean
  $done?: boolean
}

export const Steps = styled(AvatarAnt)<ExtendedAvatarProps>(({
  disabled,
  $done,
}) => {
  const theme = useTheme()
  return {
    '&&& span': { fontSize: 12 },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...(disabled && { color: theme.colorTextDescription }),
    ...($done && { color: theme.colorPrimary }),
    backgroundColor: disabled
      ? theme.colorFillContent
      : $done
        ? theme.colorBrandedButton
        : theme.colorPrimary,
  }
})

export const VerifiedCard = styled(CardStyled)(() => {
  const theme = useTheme()
  return {
    boxShadow: 'none',
    '&& .ant-card-body': {
      paddingInline: 0,
      padding: theme.padding,
    },
  }
})

export const StepContainer = styled(Row)(() => {
  const theme = useTheme()
  return {
    paddingTop: theme.paddingSM,
    paddingBottom: theme.paddingXS,
  }
})

export const RejectReasonCard = styled(CustomCard)(() => {
  const theme = useTheme()
  return {
    width: '100%',
    '& .ant-card-body': {
      padding: theme.padding,
    },
  }
})

export const DetailsCard = styled(CustomCard)(() => {
  const theme = useTheme()
  return {
    width: '100%',
    boxShadow: 'none',
    '& .ant-card-body': {
      padding: theme.paddingMD,
    },
  }
})
