import { useFindAllWalletsQuery } from '../../app/graphQl/generated/graphql.ts'
import { useMemo } from 'react'

export const useWalletNumber = (contractId: string | undefined, id: string) => {
  const {
    data: wallets,
    refetch,
    loading,
  } = useFindAllWalletsQuery({
    variables: {
      contractId: Number(contractId),
    },
    fetchPolicy: 'no-cache',
  })

  const walletNumber = useMemo(() => {
    if (wallets?.findAllWallets && wallets.findAllWallets.length > 0) {
      const contractWallets = wallets.findAllWallets
        .filter((wallet) => String(wallet.contractId) === contractId)
        .filter((wallet) => String(wallet.userId) === id)

      if (contractWallets.length === 1) {
        return contractWallets[0].wallet
      }

      if (contractWallets.length > 0) {
        return contractWallets.filter(
          (wallet) => wallet.userId === Number(id),
        )[0].wallet
      }
    }
    return undefined
  }, [wallets?.findAllWallets, contractId, id])

  const contrAgentWallet = useMemo(() => {
    if (wallets?.findAllWallets && wallets.findAllWallets.length > 0) {
      const contractWallets = wallets.findAllWallets.filter(
        (wallet) => String(wallet.contractId) === contractId,
      )

      if (contractWallets.length === 1) {
        return contractWallets[0].wallet
      }

      if (contractWallets.length > 0) {
        return contractWallets.filter(
          (wallet) => wallet.userId !== Number(id),
        )?.[0].wallet
      }
    }
    return undefined
  }, [wallets?.findAllWallets, contractId, id])

  const contractWallet = useMemo(
    () =>
      wallets?.findAllWallets.filter(
        (wallet) => wallet.userId === Number(id),
      )[0],
    [wallets?.findAllWallets, id],
  )

  return {
    contrAgentWallet,
    walletNumber,
    refetchWallets: refetch,
    contractWallet,
    loading,
  }
}
