import useToken from 'antd/es/theme/useToken'
import { FC, ReactNode, createContext } from 'react'
import themeColors from './themeColors.ts'

type UseTokenReturnType = ReturnType<typeof useToken>

export type TokenReturnType = UseTokenReturnType[1]

export interface ThemeContextType extends TokenReturnType {
  fontWeightRegular: number
  fontWeightMedium: number
  fontWeightSemiBold: number
  fontWeightBold: number
  fontWeightExtraBold: number
  fontWeightLight: number
  fontTableHeader: number
  fontTableText: number
  fontTableTextXS: number
  fontSizeXXXS: number
  fontSizeXS: number
  iconSizeXXL: number
  iconSizeXL: number
  iconSizeLG: number
  iconSizeMD: number
  iconSize: number
  iconSizeSM: number
  iconSizeButton: number
  borderRadiusXL: number
  borderRadiusXXL: number
  colorBorderTertiary: string
  colorIconSecondary: string
  colorBrandedButton: string
  hoverBrandedButton: string
  focussedBrandedButton: string
  activeBrandedButton: string
  tileBorderRadius: string
  tileBackgroundColor: string
  tileBoxShadow: string
  paddingXXL: number
  colorBgActivityLayout: string
}

interface ICustomThemeProvider {
  children: ReactNode
}

export const ThemeContext = createContext<ThemeContextType | undefined>(
  undefined,
)

const theme = {
  // font
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  fontWeightExtraBold: 800,
  fontTableHeader: 10,
  fontSizeXXXS: 9,
  fontTableText: 13,
  fontTableTextXS: 12,
  fontSizeXS: 14,
  // icon
  iconSizeXXL: 40,
  iconSizeXL: 32,
  iconSizeLG: 24,
  iconSizeMD: 18,
  iconSize: 16,
  iconSizeSM: 12,
  iconSizeButton: 20,
  // border
  borderRadiusXL: 12,
  borderRadiusXXL: 16,
  // colors
  colorBorderTertiary: '#E7E8EC',
  colorIconSecondary: '#828282',
  colorBrandedButton: themeColors.lawngreen[4],
  hoverBrandedButton: themeColors.lawngreen[5],
  focussedBrandedButton: themeColors.lawngreen[6],
  activeBrandedButton: themeColors.lawngreen[6],
  tileBorderRadius: '12px',
  tileBackgroundColor: '#ffffff',
  tileBoxShadow:
    '0px 0px 1px 0px #EAECF0, 0px 1px 3px 0px rgba(29, 41, 57, 0.08)',
  colorBgActivityLayout: '#FCFCFD',
  // paddings
  paddingXXL: 40,
}

const CustomThemeProvider: FC<ICustomThemeProvider> = ({ children }) => {
  const [, token] = useToken()

  return (
    <ThemeContext.Provider value={{ ...theme, ...token }}>
      {children}
    </ThemeContext.Provider>
  )
}

export default CustomThemeProvider
