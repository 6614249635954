import React, { FC } from 'react'
import { Spin } from 'antd'

const OpacityLoader: FC = () => {
  const loaderStyle: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  }

  return (
    <div style={loaderStyle}>
      <Spin size="large" />
    </div>
  )
}

export default OpacityLoader
