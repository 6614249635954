export const AlchemyConnected = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={63} height={56} fill="none">
      <circle cx={28} cy={28} r={28} fill="url(#a)" />
      <path
        fill="#fff"
        d="m36.988 28.927-8.57-14.535a.474.474 0 0 0-.413-.238.487.487 0 0 0-.417.232l-2.567 4.356a.922.922 0 0 0 0 .938l5.588 9.482a.948.948 0 0 0 .351.344.972.972 0 0 0 .48.125h5.132a.487.487 0 0 0 .415-.235.461.461 0 0 0 0-.469ZM14.529 36.524l8.569-14.535a.474.474 0 0 1 .415-.235.486.486 0 0 1 .414.235l2.568 4.351a.926.926 0 0 1 0 .94l-5.588 9.482a.946.946 0 0 1-.35.343.97.97 0 0 1-.479.126h-5.134a.487.487 0 0 1-.418-.235.46.46 0 0 1 .003-.472ZM23.93 37.227h17.138a.488.488 0 0 0 .415-.235.462.462 0 0 0-.001-.469l-2.564-4.354a.947.947 0 0 0-.35-.343.974.974 0 0 0-.48-.126H26.912a.974.974 0 0 0-.48.126.947.947 0 0 0-.35.343l-2.567 4.354a.462.462 0 0 0 .175.641.487.487 0 0 0 .24.063Z"
      />
      <path
        fill="#B0F127"
        stroke="#fff"
        strokeWidth={1.75}
        d="M62.125 45.5a9.625 9.625 0 1 1-19.25 0 9.625 9.625 0 0 1 19.25 0Z"
      />
      <defs>
        <linearGradient
          id="a"
          x1={9.538}
          x2={43.538}
          y1={7.385}
          y2={50.308}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0055FB" />
          <stop offset={1} stopColor="#15A9F3" />
        </linearGradient>
      </defs>
    </svg>
  )
}
